<template>
    <div class="main">
        <div class="content">
            <div class="success">
                <div class="ok">
                    <p class="money">₹{{ amount }}</p>
                    <p class="msg">Paid Successfully</p>
                </div>
                <p class="text text1">To {{ paymentName }}</p>
                <p class="text text2">{{ curdate }}</p>
                <p class="text text3">OrderId Ref No.{{ orderId }}</p>
                <p class="text text3" v-if="utr">UPI Ref No.{{ utr }}</p>
                <p class="text text4">The remittance has been received</p>
            </div>
            <!-- <div class="fail">
                <img src="../../assets/images/close.png" />
                <p class="text failtext1">Payment of Rs.100 failed!</p>
                <p class="text failtext2">Unfortunately your payment request could not be completed. Please try again.</p>
                <p class="text failtext3">08:44 AM,17 Sep 2023</p>
                <button>Tye Again</button>
                <hr />
                <p class="text failtext4">Jio feiled</p>
                <p class="text failtext5">Recharge Amount-口100</p>
                <p class="text failtext6">OrderID:2062626611</p>
            </div> -->

        </div>
        <div class="footer">
            <div class="footLogo">
                <p>Powered by</p>
                <img src="../../assets/images/upi.jpg">
            </div>

            <hr />

            <div class="paragraph">
                <p>Tips:</p>
                <p>lf your payment is successful and your account has not increased the amountplease prowide your order
                    number and
                    payment voucher to contact us assoon as possilble.</p>
            </div>
            <div class="mail">
                <i class="envelope"></i>
                {{ $config.SYS_AIOPAY_EMAIL }}
            </div>
        </div>
    </div>
</template>
<script>
import _validate from "@/utils/validate";

export default {
    data: function () {
        return {
            dialogState: false,
            orderId: "",
            utr: "",
            amount: "0", // 支付金额默认
            resData: {},
            payOrderInfo: {}, //订单信息,
            singleTimer: null,
            curdate: '',
            paymentName:"Anonymous"

        };
    },
    created() {
        this.$moment().locale('en')
        this.curdate = this.$moment().format('HH:mm A ,DD MMMM YYYY');

        if (!_validate.empty(this.$route.query.am)) {
            this.amount = this.$route.query.am;
        }
        if (!_validate.empty(this.$route.query.utr)) {
            this.utr = this.$route.query.utr;
        }
        if (!_validate.empty(this.$route.query.orderId)) {
            this.orderId = this.$route.query.orderId;
        }
    }
}

</script>
<style>
body,
html {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
<style scoped>
.main {
    background-color: #eee;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    padding: .2rem;
}

.content .success,
.content .fail {
    border-radius: .6rem;
    background-color: #fff;
    padding: .2rem;
    padding-bottom: 1rem;
}

.content .success .ok {
    border-radius: .6rem;
    background-color: rgb(236, 251, 244);
    font-size: 1rem;
    font-family: 黑体;
    font-weight: 600;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}

.content .success .ok p {
    margin: 0;
}

.content .success .ok p.msg {
    font-size: .6rem;
    margin-top: .2rem;
}

.content .success .ok p.money {
    background: url(../../assets/images/payment_success.jpg) no-repeat calc(50% + 1.4rem) center;
    background-size: 1rem;
    padding-right: 1rem;
}

.text {
    font-size: .2rem;
}

.text.text1 {
    margin-top: 1.4rem;
    font-size: .5rem;
    font-weight: 600;
}

.text.text2 {
     font-size: .4rem;
    margin-top: .6rem;
}

.text.text2,
.text.text3 {
     font-size: .45rem;
    color: rgb(165, 165, 165);
}

.text.text4 {
    margin-top: .6rem;
    font-weight: 600;
    font-size: .4rem;
}



/* fail */
.content .fail>img {
    width: 1.4rem;
    margin-top: 1.6rem;
}


.content .fail .failtext1 {
    font-weight: 600;
    font-size: .6rem;
    margin-bottom: 0;
}


.content .fail .failtext2 {
    margin-top: .2rem;
    font-size: .4rem;
    font-weight: 600;
}

.content .fail .failtext3 {
    font-size: .4rem;
    color: rgb(165, 165, 165);
}

.content .fail .failtext4 {
    font-weight: 600;
    font-size: .4rem;
}

.content .fail .failtext5 {
    font-weight: 600;
    font-size: .4rem;
}

.content .fail .failtext6 {
    font-size: .4rem;
    color: rgb(165, 165, 165);
}

.content .fail>button {
    background-color: rgb(54, 122, 247);
    color: #fff;
    font-size: .4rem;
    border: none;
    border-radius: 0.5rem;
    display: block;
    width: 4rem;
    height: 1.2rem;
    margin: 0.6rem auto;
}

.content .fail>hr {
    width: 1rem;
    margin-top: 1.6rem;
}



.footer {
    width: 100%;
    font-size: .4rem;
    background-color: #eee;
    padding-bottom: 1.7rem;
    /* margin-top: 1rem; */
}

.footer hr {
     margin: .2rem .6rem;
  border: 1px solid #fff;
}

.footer .footLogo {
    text-align: center;
}

.footer .footLogo p {
    margin: 0;
    color: #5d5d5d;
    font-size: .2rem;

}

.footer .footLogo img {
    width: 1.4rem;
    height: auto;
    margin-top: .2rem;
}

.footer .paragraph {
    text-align: left;
    padding: 0 .6rem;
    font-size: .2rem;
    color: #939393;
}

.footer .mail {
    text-align: left;
    padding: 0 0.6rem;
    font-size: .2rem;
}

.envelope::after {
    font-family: fontAwesome;
    content: "\f0e0";
    font-weight: normal;
    font-style: normal;
    font-size: .4rem;
}
</style>